import React, { useEffect } from 'react';

import { ParticlesOptions } from './particles-options';
import './styles.css';

const HeroBackground = () => {
  useEffect(() => {
    window.particlesJS('hero-background', ParticlesOptions);
  }, []);

  return (
    <div
      id="hero-background"
      className="absolute inset-0"></div>
  );
};

export default HeroBackground;
