import React from 'react';
import { Linkedin } from 'lucide-react';

import WhatsappIcon from '../WhatsappIcon';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-black py-32 text-gray-300">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Bloque general para pantallas grandes */}
        <div className="hidden justify-between md:flex">
          <div className="space-y-6 text-left">
            <div className="flex space-x-6">
              <a
                href="https://www.linkedin.com/company/titanity"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white">
                <Linkedin size={32} />
              </a>
              <a
                href="https://wa.me/+5493517636579"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white">
                <WhatsappIcon />
              </a>
            </div>

            <div>
              <a
                href="/privacy-policy.pdf"
                target="_blank"
                className="mr-2 font-display text-gray-400 hover:text-white">
                Privacy Policy
              </a>
              <a
                href="/terms-of-use.pdf"
                target="_blank"
                className="mx-2 font-display text-gray-400 hover:text-white">
                Terms of Use
              </a>
            </div>
          </div>

          <div className="space-y-4 text-right">
            <p className="font-display">
              <span className="text-lg font-semibold text-gray-500">
                Address
              </span>
              <br />
              30 N Gould St
              <br />
              Sheridan, WY 82801
            </p>

            <p className="font-display">
              <span className="mt-4 font-display text-lg font-semibold text-gray-500">
                Contact us
              </span>
              <br />
              <a
                href="mailto:contact@titanity.com"
                className="font-display text-orange-400 hover:underline">
                contact@titanity.co
              </a>

              <br />
              <a
                href="tel:+5493517636579"
                className="font-display text-orange-400 hover:underline">
                +54 9 (351) 763 6579
              </a>
            </p>
          </div>
        </div>

        {/* Bloque general para pantallas pequeñas */}
        <div className="flex flex-col items-center space-y-24 md:hidden">
          <div className="flex space-x-6">
            <a
              href="https://www.linkedin.com/company/titanity"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white">
              <Linkedin size={32} />
            </a>
            <a
              href="https://wa.me/1234567890"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white">
              <WhatsappIcon />
            </a>
          </div>

          <div className="text-center">
            <p className="font-display">
              <span className="text-lg font-semibold text-gray-500">
                Address
              </span>
              <br />
              30 N Gould St
              <br />
              Sheridan, WY 82801
            </p>

            <p className="mt-4">
              <span className="font-display text-lg font-semibold text-gray-500">
                Contact us
              </span>
              <br />
              <a
                href="mailto:contact@titanity.com"
                className="font-display text-orange-400 hover:underline">
                contact@titanity.co
              </a>

              <br />
              <a
                href="tel:+5493517636579"
                className="font-display text-orange-400 hover:underline">
                +54 9 (351) 763 6579
              </a>
            </p>
          </div>

          <div className="text-center">
            <a
              href="/privacy-policy.pdf"
              target="_blank"
              className="mx-2 font-display text-gray-400 hover:text-white">
              Privacy Policy
            </a>
            <a
              href="/terms-of-use.pdf"
              target="_blank"
              className="mx-2 font-display text-gray-400 hover:text-white">
              Terms of Use
            </a>
          </div>
        </div>

        <div className="mt-8 text-center">
          <p className="font-display">
            &copy; {currentYear} Titanity LLC. All rights reserved.
          </p>
          <p className="font-display text-sm text-gray-400">
            "Empowering your business with innovative solutions"
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
