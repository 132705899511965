const initGA = () => {
    const script = document.createElement('script');
    
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-BKEJGM3WZT`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    
    function gtag() {
        window.dataLayer.push(arguments);
    }
    
    gtag('js', new Date());
    gtag('config', 'G-BKEJGM3WZT');
};

initGA();
