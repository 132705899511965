import React from 'react';
import { motion } from 'framer-motion';
import { Waypoints } from 'lucide-react';

const links = [
  { label: 'Home', href: '#home' },
  { label: 'About', href: '#about' },
  { label: 'Services', href: '#services' },
  { label: 'Contact', href: '#contact' },
];

const MenuContent = ({ closeMenu }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-40 bg-orange-500">
      <div className="container mx-auto flex items-center px-4 pt-8 text-left sm:px-6 lg:px-8">
        <Waypoints size={24} className="mr-2 text-black" />
        <h1 className="font-display text-4xl text-black">
          t<span className="inline-block align-baseline font-display">it</span>
          anity
        </h1>
      </div>

      <div className="flex h-full flex-col items-end justify-end p-12">
        <div className="mb-36 flex flex-col items-end space-y-6">
          {links.map((link, index) => (
            <motion.a
              key={link.label}
              href={link.href}
              onClick={closeMenu}
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: index * 0.2, duration: 0.5 }}
              className="font-display text-4xl text-black hover:underline md:text-5xl lg:text-6xl">
              {link.label}
            </motion.a>
          ))}

          {/* Language switcher */}
          {/* <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: links.length * 0.2, duration: 0.5 }}
            className="mt-8 flex items-center space-x-8 text-black">
            <button
              onClick={() => alert('Language changed to English')}
              className="font-display text-2xl hover:underline md:text-3xl lg:text-4xl">
              EN
            </button>
            <span className="text-2xl md:text-3xl lg:text-4xl">|</span>
            <button
              onClick={() => alert('Language changed to Spanish')}
              className="font-display text-2xl hover:underline md:text-3xl lg:text-4xl">
              ES
            </button>
          </motion.div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default MenuContent;
