import React from 'react';
import { motion } from 'framer-motion';
import { Waypoints } from 'lucide-react';

import HeroImage from '../../assets/hero_v3.png';
import HeroBackground from '../HeroBackground';

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const MainSection = () => {
  return (
    <>
      <HeroBackground />
      <section
        id="home"
        className="container relative z-10 mx-auto min-h-screen flex-col px-0 sm:px-6 lg:px-8">
        <div className="relative w-full px-4 py-8 text-left sm:px-0">
          <motion.div
            variants={fadeIn}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1 }}
            className="flex items-center">
            <Waypoints size={24} className="mr-2 text-orange-500" />
            <h1 className="font-display text-4xl text-white sm:text-4xl">
              t
              <span className="inline-block align-baseline font-display">
                it
              </span>
              anity
            </h1>
          </motion.div>
        </div>
        <div className="flex flex-col items-center justify-center md:flex-col lg:flex-row">
          <div className="my-8 flex-1 lg:my-0">
            <motion.div
              variants={fadeIn}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1, delay: 0.5 }}>
              <h1 className="text-center font-display text-4xl text-white md:text-4xl lg:text-left lg:text-6xl px-4 md:px-0">
                Crafting <span className="text-orange-500">high-quality</span>,
                <br />
                bespoke solutions for your enterprise.
              </h1>
            </motion.div>
          </div>
          <div className="flex flex-1 items-center justify-center align-middle">
            <motion.div
              variants={fadeIn}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1, delay: 1 }}
              className="relative">
              <img
                src={HeroImage}
                alt={'Your system in any device.'}
                width={500}
                className="md:my-32 px-4 md:px-0"
              />
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainSection;
