import { motion } from 'framer-motion';

const ServiceItem = ({ title, description, image, phrase, isFirstItem }) => {
  const selectedStyles = isFirstItem ? 'mt-4' : 'mt-16';

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8, ease: 'easeOut', delay: 0.1 }}
      className={`mx-auto ${selectedStyles} rounded-xl border-2 border-dotted border-orange-500 bg-white font-display`}>
      <div className="md:flex">
        {!!image && (
          <div className="md:shrink-0">
            <img
              className="h-80 w-full rounded-t-xl object-cover shadow-md md:h-full md:w-72 md:rounded-l-xl md:rounded-br-none md:rounded-tr-none"
              src={image}
              alt="Modern building architecture"
            />
          </div>
        )}
        <div className="p-8">
          {!!phrase && (
            <div className="text-lg font-semibold uppercase tracking-wide text-orange-500">
              {phrase}
            </div>
          )}
          <h2 className="mt-1 block text-2xl md:text-4xl font-medium leading-tight text-black">
            {title}
          </h2>
          <p className="mt-2 text-lg md:text-xl text-slate-500">{description}</p>
        </div>
      </div>
    </motion.div>
  );
};

export default ServiceItem;
