import { motion } from 'framer-motion';

import ServiceItem from './ServiceItem';
import servicesItems from './services-data.json';

const Services = () => {
  return (
    <div
      id="services"
      className="container mx-auto px-4 pt-16 lg:max-w-5xl lg:px-0">
      <div className="">
        <motion.h2
          className="text-left font-display text-4xl font-bold lg:text-5xl"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}>
          How we work
        </motion.h2>
      </div>
      {servicesItems.map((step, index) => (
        <ServiceItem
          key={index}
          title={step.title}
          description={step.description}
          image={step.image}
          phrase={step.phrase}
          isFirstItem={index === 0}
        />
      ))}
    </div>
  );
};

export default Services;
