import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ExternalLink, Copy } from 'lucide-react';

import contactData from './contact-data.json';
import TooltipAnimation from './TooltipAnimation';

const ContactButton = ({
  href,
  text,
  copyText,
  copyTooltipText,
  onCopyText,
}) => {
  const handleCopy = () => {
    // Note: navigator API does not work when using HTTP protocol, use HTTPS to try it.
    navigator.clipboard.writeText(copyText);
    onCopyText(copyTooltipText);
  };

  return (
    <div className="flex h-12 w-full max-w-xs items-center overflow-hidden rounded-lg border-2 border-black text-black">
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="flex h-full flex-grow items-center transition-colors duration-300 hover:underline">
        <ExternalLink size={16} className="mx-2" />
        {text}
      </a>
      <button
        onClick={handleCopy}
        className="h-full px-4 transition-colors duration-300 hover:bg-black hover:text-orange-500">
        <Copy size={16} />
      </button>
    </div>
  );
};

const Contact = () => {
  const [tooltipText, setTooltipText] = useState(false);

  const onCopyText = (copyTooltipText) => {
    setTooltipText(copyTooltipText);
    setTimeout(() => setTooltipText(''), 2000);
  };

  return (
    <section
      id="contact"
      className="container mx-auto flex py-32 flex-col items-center justify-center font-display lg:flex-row overflow-hidden"
    >
      <motion.div
        className="flex w-full flex-col p-4 text-center lg:w-1/2 lg:text-left"
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
        style={{ overflow: 'hidden' }}
      >
        <h2 className="mb-6 text-6xl font-bold text-black lg:text-8xl">
          Let's make
          <br />
          it happen
        </h2>
        <p className="text-2xl text-orange-500">
          Take the first step, and we'll handle the rest.
        </p>
      </motion.div>

      <motion.div
        className="flex w-full flex-col items-center justify-end space-y-4 p-4 lg:w-1/2 lg:items-end lg:space-y-4"
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
        style={{ overflow: 'hidden' }}
      >
        <div className="flex h-8 w-full max-w-xs overflow-hidden">
          <AnimatePresence>
            {!!tooltipText && <TooltipAnimation label={'Copied!'} />}
          </AnimatePresence>
        </div>
        {contactData.map((contact, index) => (
          <ContactButton
            key={index}
            href={contact.href}
            text={contact.text}
            copyText={contact.copyText}
            copyTooltipText={contact.copyTooltipText}
            onCopyText={onCopyText}
          />
        ))}
      </motion.div>
    </section>
  );
};

export default Contact;
