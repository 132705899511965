import React from 'react';
import { motion } from 'framer-motion';

const TooltipAnimation = ({ label }) => {
  return (
    <div className="relative inline-block h-full w-full">
      <motion.div
        className="absolute right-0 top-0 -translate-x-1/2 transform rounded-full bg-transparent text-black"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}>
        {label}
        <div className="relative flex items-center justify-center">
          {/* Círculo izquierdo */}
          <motion.div
            className="h-1 w-1 rounded-full bg-orange-600"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          />
          {/* Línea animada */}
          <motion.div
            className="h-0.5 bg-orange-600"
            initial={{ width: 0 }}
            animate={{ width: '40px' }} // Ajusta el ancho
            transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.3 }}
            style={{ transformOrigin: 'left center' }} // La línea se extiende desde la izquierda
          />
          {/* Círculo derecho */}
          <motion.div
            className="h-1 w-1 rounded-full bg-orange-600"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut', delay: 0.8 }} // El círculo derecho se anima después de la línea
          />
        </div>
      </motion.div>
    </div>
  );
};

export default TooltipAnimation;
