import React from 'react';
import 'particles.js';

import About from './components/About';
import Menu from './components/Menu';
import MainSection from './components/MainSection';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
// import CookieBanner from './components/CookieBanner';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Menu />
      <MainSection />
      <About />
      <Services />
      <Contact />
      <Footer />
      {/* <CookieBanner /> */}
    </div>
  );
};

export default App;
