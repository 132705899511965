import React from 'react';
import { motion } from 'framer-motion';

const MenuToggle = ({ isOpen }) => {
  return (
    <div
      className={`relative flex h-12 w-32 items-center justify-center rounded-full p-2 ${
        isOpen ? 'bg-transparent' : 'bg-orange-500'
      }`}>
      <div className="relative flex h-8 w-8 flex-shrink-0 items-center justify-center">
        <motion.div
          initial={false}
          animate={{
            rotate: isOpen ? 45 : 0,
            y: isOpen ? 0 : -4, // Reducida la distancia vertical cuando está cerrado
          }}
          transition={{ duration: 0.2 }}
          className="absolute h-1 w-full rounded-full bg-black"
          style={{ height: '3px' }}
        />
        <motion.div
          initial={false}
          animate={{
            rotate: isOpen ? -45 : 0,
            y: isOpen ? 0 : 4, // Reducida la distancia vertical cuando está cerrado
          }}
          transition={{ duration: 0.2 }}
          className="absolute h-1 w-full rounded-full bg-black"
          style={{ height: '3px' }}
        />
      </div>
      <span className="ml-2 flex items-center font-display text-black text-xl">
        Menu
      </span>
    </div>
  );
};

export default MenuToggle;
