import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <section
      id="about"
      className="container mx-auto flex items-center justify-center px-4 pt-16 md:pt-0 lg:px-0 lg:pt-16">
      <div className="w-full lg:max-w-5xl">
        <motion.h2
          className="mb-4 text-left font-display text-4xl font-bold lg:text-5xl"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}>
          About
        </motion.h2>
        <motion.p
          className="text-left font-display text-lg font-light leading-loose md:text-justify lg:text-xl"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}>
          At <span className="font-medium text-orange-400">titanity</span>,
          we’re not just about building software; we’re about crafting solutions
          that truly resonate with your business. Our first step is always
          listening—getting to know your needs and understanding where
          technology can make a real impact.
        </motion.p>
        <motion.p
          className="mt-4 text-left font-display text-lg font-light leading-loose md:text-justify lg:text-xl"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}>
          We handpick the right tools and technologies to create systems that
          fit seamlessly with your industry. With experience across sectors like
          airlines, entertainment, fintech, tourism, fitness and sports, we’re
          adept at adapting to any challenge. Our team is made up of seasoned
          professionals who are passionate about delivering fast, effective
          results, all while keeping your budget in mind.
        </motion.p>
        <motion.p
          className="mt-4 text-left font-display text-lg font-light leading-loose md:text-justify lg:text-xl"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}>
          We believe in building more than just software—we’re here to build
          trust, relationships, and systems that{' '}
          <span className="font-medium text-orange-400">
            empower your business
          </span>
          . At titanity, technology is more than a tool; it’s the key to
          unlocking your potential.
        </motion.p>
      </div>
    </section>
  );
};

export default About;
