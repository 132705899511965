import React, { useState } from 'react';
import { motion } from 'framer-motion';
import MenuContent from './MenuContent';
import MenuToggle from './MenuToggle';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="relative">
      <motion.div
        initial={false}
        animate={{
          scale: isOpen ? 100 : 1,
          transition: { duration: 0.7 },
        }}
        className="fixed bottom-4 right-4 z-40 flex items-center justify-center rounded-full bg-orange-500"
      />
      <div className="fixed bottom-4 right-4 z-50 flex items-center justify-center">
        <button onClick={toggleMenu} className="p-4">
          <MenuToggle isOpen={isOpen} />
        </button>
      </div>
      {isOpen && <MenuContent closeMenu={toggleMenu} />}
    </nav>
  );
};

export default Menu;
